import { PropsWithChildren, FC, useState } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { ButtonVariants } from 'constants/common';
import { DropdownMenuItems } from 'interfaces/shared.interface';
import { Button } from 'shared-components/index';
import { cn } from 'utils/global';

interface Props {
  elements: DropdownMenuItems[];
  onItemClick?: (element: DropdownMenuItems) => void;
  dataCy?: string;
  buttonVariant?: ButtonVariants;
  hidden?: boolean;
  disabled?: boolean;
  icon?: IconProp;
  className?: string;
}

const DropdownMenu: FC<PropsWithChildren<Props>> = ({
  elements,
  hidden,
  buttonVariant,
  dataCy,
  onItemClick,
  children,
  disabled,
  icon,
  className,
}) => {
  const { t } = useTranslation();

  const [isVisibleDropdown, setIsVisibleDropdown] = useState(false);

  const hasVisibleMenuItems = elements.some((item) => !item.hidden);

  const toggleMenuVisibility = () => {
    setIsVisibleDropdown(!isVisibleDropdown);
  };

  if (!hasVisibleMenuItems || hidden) {
    return null;
  }

  return (
    <>
      {isVisibleDropdown && <div onClick={toggleMenuVisibility} className="fixed w-full h-full left-0 top-0 z-10" />}

      <div
        data-cy={dataCy}
        className={cn(className, 'relative flex justify-center', {
          'cursor-not-allowed': disabled,
          'cursor-pointer': !disabled,
        })}
      >
        <Button
          icon={icon}
          dataCy="dropdown-menu-button"
          variant={buttonVariant || ButtonVariants.TRANSPARENT}
          type="button"
          onClick={() => !disabled && setIsVisibleDropdown(!isVisibleDropdown)}
          className={cn({ 'cursor-not-allowed': disabled })}
        >
          {children || (
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              className={cn('text-slate-500 dark:text-white', { 'text-slate-300 dark:text-slate-500': disabled })}
            />
          )}
        </Button>

        {isVisibleDropdown && (
          <div
            data-cy="dropdown-menu"
            className="origin-top-right border border-lightGray6 absolute right-0 mt-2 py-2 w-56 rounded-md shadow-md z-20 bg-white dark:bg-darkBlue4 dark:border-darkBlue dark:border items-center"
          >
            {elements
              .filter(({ hidden }) => !hidden)
              .map((element) => (
                <button
                  data-cy={element.dataCy}
                  key={element.name}
                  onClick={() => {
                    if (!element.disabled) {
                      onItemClick?.(element);
                      element.actionOnClick();
                      toggleMenuVisibility();
                    }
                  }}
                  disabled={element.disabled}
                  className={cn(
                    element.className,
                    'text-darkGray dark:text-white block pl-4 font-medium text-sm w-full text-left hover:bg-gray dark:hover:bg-blue',
                    {
                      'cursor-not-allowed opacity-50 hover:bg-inherit! dark:hover:bg-inherit!': element.disabled,
                      flex: element.icon,
                    }
                  )}
                >
                  {element.icon && (
                    <FontAwesomeIcon icon={element.icon} className={cn(element.iconClassName, 'flex')} />
                  )}
                  {t(element.name)}
                </button>
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DropdownMenu;
