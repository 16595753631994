import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import generatePicker from 'antd/es/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { FormItem } from 'interfaces/shared.interface';
import { ErrorMessage } from 'shared-components';
import { cn } from 'utils/global';

const DatePicker = generatePicker(dateFnsGenerateConfig);

export type Props = {
  label?: string;
  error?: string;
  name: string;
  control: Control;
  required?: boolean;
  icon?: IconDefinition;
  placeHolder?: string;
} & Omit<FormItem, 'component'>;

const DatePickerInput = ({
  className,
  name,
  dataCy,
  control,
  labelClassName,
  inputClassName,
  label,
  disabled,
  required,
  icon,
  error,
  placeHolder = 'SelectDate',
}: Props) => {
  const { t } = useTranslation();

  return (
    <div data-cy={dataCy} className={className}>
      {label && (
        <label
          className={cn('block text-gray-700 mb-2 text-sm font-normal dark:text-gray', labelClassName, {
            'opacity-70': disabled,
          })}
        >
          {t(label)}
          {label && required && ' *'}
        </label>
      )}
      <>
        {icon && (
          <div className="flex justify-center items-center h-10 w-9 rounded bg-lightGray4 border-lightGray2 rounded-e-none dark:bg-gray">
            <FontAwesomeIcon className="h-3 dark:text-darkGray6" icon={icon} />
          </div>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <DatePicker
              name={field.name}
              value={field.value ? new Date(field.value) : ''}
              onChange={field.onChange}
              onBlur={field.onBlur}
              format={DAY_MONTH_YEAR_FORMAT_WITH_SLASHES}
              className={cn(
                'py-[7px] px-2.5 rounded !text-darkGray date-picker dark:bg-darkBlue4 dark:hover:bg-darkBlue4',
                { '!border-error focus:ring': !!error },
                inputClassName
              )}
              placeholder={t(placeHolder)}
            />
          )}
        />
      </>
      {error && <ErrorMessage dataCy="error-message">{t(error)}</ErrorMessage>}
    </div>
  );
};

export default DatePickerInput;
