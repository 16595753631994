import { useTranslation } from 'react-i18next';

import { useDeleteSubscriptionMutation } from 'apis/subscriptions.api';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { SharedSubscription, SUBSCRIPTION_STATUS } from 'interfaces/subscriptions-admin.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { DropdownMenu, ErrorModal, SubscriptionPrice, TableData, TableRow, WarningModal } from 'shared-components';
import { successNotify } from 'utils/notifications';

import EditSubscriptionModalContent from '../subscriptions-admin/edit-subscription/EditSubscriptionModalContent';
import SubscriptionManagementModal from '../subscriptions-admin/subscriptions-management-modal/SubscriptionManagementModal';

interface Props {
  subscription: SharedSubscription;
  refetchSubscriptions: VoidFunction;
}

const SubscriptionsRow = ({ subscription, refetchSubscriptions }: Props) => {
  const { t } = useTranslation();

  const permissions = useAppSelector(selectUserPermissions);

  const [isOpenInfoModal, handleOpenInfoModal, handleCloseInfoModal] = useOpen();
  const [isOpenEditModal, handleOpenEditModal, handleCloseEditModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [deleteSubscription, { error: deleteSubscriptionError }] = useDeleteSubscriptionMutation();
  const errorData = (deleteSubscriptionError as CommonError)?.data || {};

  const {
    id,
    displayName,
    dailyPrice,
    weeklyPrice,
    monthlyPrice,
    annualPrice,
    waitingDayAfterExpire,
    expiringSubscriptionDisplayName,
    status,
  } = subscription;

  const isSubscriptionFree = !dailyPrice && !weeklyPrice && !monthlyPrice && !annualPrice;

  const menuItemsAction = [
    {
      dataCy: 'subscription-menu-item-edit-button',
      name: 'Edit',
      actionOnClick: handleOpenEditModal,
      hidden: !permissions[Permissions.PAGES_HOST_SUBSCRIPTIONS_EDIT],
    },
    {
      dataCy: 'subscription-menu-item-delete-button',
      name: 'Delete',
      actionOnClick: handleOpenInfoModal,
      hidden: !permissions[Permissions.PAGES_HOST_SUBSCRIPTIONS_DELETE],
    },
  ];

  const handleDeleteSubscription = async () => {
    try {
      await deleteSubscription(id).unwrap();
      refetchSubscriptions();
      handleCloseInfoModal();
      successNotify(t('SuccessfullyDeleted'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <SubscriptionManagementModal
        isOpen={isOpenEditModal}
        handleClose={handleCloseEditModal}
        heading="UpdateSubscription"
      >
        <EditSubscriptionModalContent userId={id} handleClose={handleCloseEditModal} refetch={refetchSubscriptions} />
      </SubscriptionManagementModal>

      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('SubscriptionDeleteWarningMessage', { 0: displayName })}
        isOpen={isOpenInfoModal}
        handleClose={handleCloseInfoModal}
        handleConfirm={handleDeleteSubscription}
      />

      <TableRow dataCy="subscriptions-table-row">
        <TableData dataCy="subscriptions-table-row-item-name">{displayName}</TableData>
        <TableData>
          <SubscriptionPrice value={'PerDay'} price={dailyPrice} />
          <SubscriptionPrice value={'PerWeek'} price={weeklyPrice} />
          <SubscriptionPrice value={'PerMonth'} price={monthlyPrice} />
          <SubscriptionPrice value={'PerYear'} price={annualPrice} />
          {isSubscriptionFree && <p>Free</p>}
        </TableData>
        <TableData>{waitingDayAfterExpire}</TableData>
        <TableData>{expiringSubscriptionDisplayName}</TableData>
        <TableData dataCy="subscriptions-table-row-item-status">
          {status.name === SUBSCRIPTION_STATUS.ACTIVE ? t('Active') : t('NotActive')}
        </TableData>

        <TableData>
          <DropdownMenu dataCy="subscriptions-dropdown-menu" elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};

export default SubscriptionsRow;
