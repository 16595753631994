import { endOfDay, startOfDay, subDays } from 'date-fns';

import {
  DOCUSIGN_COLUMNS,
  DOCUSIGN_FILTERS_FORM_FIELD_NAMES,
  DOCUSIGN_REQUEST_FORM_FIELDS,
  DOCUSIGN_SIGNERS_FORM_FIELDS,
} from 'interfaces/integrations/docusign.interface';
import { FieldTypes, FormItem, TableColumn } from 'interfaces/shared.interface';
import { Input, SelectFormInput, TextArea } from 'shared-components';
import DatePickerInput from 'shared-components/DatePickerInput';

import { BASE_FILTER_FIELDS } from './common';
import { EMAIL_PATTERN } from './global';

export const STATUS_FILTERS_OPTIONS = [
  { label: 'Completed', value: '0' },
  { label: 'Correct', value: '1' },
  { label: 'Created', value: '2' },
  { label: 'Declined', value: '3' },
  { label: 'Deleted', value: '4' },
  { label: 'Delivered', value: '5' },
  { label: 'Sent', value: '6' },
  { label: 'Signed', value: '7' },
  { label: 'Templete', value: '8' },
  { label: 'TransferCompleted', value: '9' },
  { label: 'Voited', value: '10' },
];

export const DOCUSIGN_FILTERS_FIELDS_DEFAULT_VALUES = {
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [DOCUSIGN_FILTERS_FORM_FIELD_NAMES.CREATION_DATE_FROM]: new Date(startOfDay(subDays(new Date(), 7))).toISOString(),
  [DOCUSIGN_FILTERS_FORM_FIELD_NAMES.CREATION_DATE_TO]: new Date(endOfDay(new Date())).toISOString(),
};

export const DOCUSIGN_FILTER_FORM_FIELDS: FormItem[] = [
  {
    name: DOCUSIGN_FILTERS_FORM_FIELD_NAMES.CREATION_DATE_FROM,
    component: DatePickerInput,
    type: FieldTypes.DATE,
    className: 'ml-10 w-30',
    inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2',
    label: 'CreationDateFrom',
    dataCy: 'start-date',
    isFilter: true,
  },
  {
    name: DOCUSIGN_FILTERS_FORM_FIELD_NAMES.CREATION_DATE_TO,
    label: 'CreationDateTo',
    className: 'ml-10 w-30',
    inputClassName: 'w-full dark:hover:bg-transparent dark:bg-transparent [&_svg]:fill-lightGray2',
    type: FieldTypes.DATE,
    dataCy: 'is-creation-date-to-select',
    component: DatePickerInput,
    isFilter: true,
  },
  {
    name: DOCUSIGN_FILTERS_FORM_FIELD_NAMES.STATUS,
    label: 'Status',
    className: 'ml-10 w-30 mt-1',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    placeholder: 'SelectStatus',
    dataCy: 'is-status-select',
    component: SelectFormInput,
    options: [...STATUS_FILTERS_OPTIONS],
    isFilter: true,
  },
];

export const DOCUSIGN_COLUMNS_TITLES = {
  [DOCUSIGN_COLUMNS.CREATION_DATE]: 'CreationDate',
  [DOCUSIGN_COLUMNS.SIGNING_DATE]: 'SigningDate',
  [DOCUSIGN_COLUMNS.DOCUMENT_NAME]: 'DocumentName',
  [DOCUSIGN_COLUMNS.RECIPIENTS]: 'Recipients',
  [DOCUSIGN_COLUMNS.STATUS]: 'Status',
  [DOCUSIGN_COLUMNS.ACTION]: '',
};

export const DOCUSIGN_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: DOCUSIGN_COLUMNS.CREATION_DATE,
    title: DOCUSIGN_COLUMNS_TITLES[DOCUSIGN_COLUMNS.CREATION_DATE],
    width: 'w-2/12',
  },
  {
    columnId: DOCUSIGN_COLUMNS.SIGNING_DATE,
    title: DOCUSIGN_COLUMNS_TITLES[DOCUSIGN_COLUMNS.SIGNING_DATE],
    width: 'w-2/12',
  },
  {
    columnId: DOCUSIGN_COLUMNS.DOCUMENT_NAME,
    title: DOCUSIGN_COLUMNS_TITLES[DOCUSIGN_COLUMNS.DOCUMENT_NAME],
    width: 'w-2/12',
  },
  {
    columnId: DOCUSIGN_COLUMNS.RECIPIENTS,
    title: DOCUSIGN_COLUMNS_TITLES[DOCUSIGN_COLUMNS.RECIPIENTS],
    width: 'w-4/12',
  },
  {
    columnId: DOCUSIGN_COLUMNS.STATUS,
    title: DOCUSIGN_COLUMNS_TITLES[DOCUSIGN_COLUMNS.STATUS],
    width: 'w-2/12',
  },
  {
    columnId: DOCUSIGN_COLUMNS.ACTION,
    title: DOCUSIGN_COLUMNS_TITLES[DOCUSIGN_COLUMNS.ACTION],
    width: 'w-1/12',
  },
];

export const DOCUSIGN_REQUEST_FORM_FIELD = (): FormItem[] => {
  return [
    {
      name: DOCUSIGN_REQUEST_FORM_FIELDS.DOCUMENT_NAME,
      label: 'DocumentName',
      labelClassName: 'text-sm',
      type: FieldTypes.TEXT,
      required: true,
      inputClassName: 'w-full !text-sm',
      dataCy: 'document-name-field',
      className: 'mb-4',
      component: Input,
      validation: {
        required: 'ThisFieldIsRequired',
      },
    },
    {
      name: DOCUSIGN_REQUEST_FORM_FIELDS.EMAIL_SUBJECT,
      label: 'EmailSubject',
      labelClassName: 'text-sm',
      type: FieldTypes.TEXT,
      required: true,
      inputClassName: 'w-full !text-sm',
      dataCy: 'document-email-subject-field',
      className: 'mb-4',
      component: Input,
      validation: {
        required: 'ThisFieldIsRequired',
      },
    },
    {
      name: DOCUSIGN_REQUEST_FORM_FIELDS.EMAIL_MESSAGE,
      label: 'EmailMessage',
      labelClassName: 'text-sm',
      type: FieldTypes.TEXT,
      inputClassName: 'w-full',
      dataCy: 'document-email-message-field',
      className: 'mb-4',
      component: TextArea,
    },
  ];
};

export const DOCUSIGN_SIGNERS_FORM_FIELD = (): FormItem[] => {
  return [
    {
      name: DOCUSIGN_SIGNERS_FORM_FIELDS.SIGNERS_NAME,
      label: 'SignerName',
      labelClassName: 'text-sm w-24',
      type: FieldTypes.TEXT,
      required: true,
      inputClassName: 'w-full !text-sm',
      dataCy: 'signer-name-field',
      className: 'mb-4',
      component: Input,
      validation: {
        required: 'ThisFieldIsRequired',
      },
    },
    {
      name: DOCUSIGN_SIGNERS_FORM_FIELDS.SIGNERS_EMAIL,
      label: 'SignerEmail',
      labelClassName: 'text-sm w-24',
      type: FieldTypes.TEXT,
      required: true,
      inputClassName: 'w-full !text-sm',
      dataCy: 'signer-email-field',
      className: 'mb-4',
      component: Input,
      validation: {
        required: 'ThisFieldIsRequired',
        pattern: { message: 'InvalidEmail', value: EMAIL_PATTERN },
      },
    },
  ];
};

export const PLACEMENT_TYPE: Record<string, number> = {
  Signature: 1,
  Date: 3,
  Initials: 2,
};
