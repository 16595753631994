import { PropsWithChildren } from 'react';

import { cn } from 'utils/global';

type Props = {
  dataCy?: string;
  className?: string;
};

const TableRow = ({ children, className, dataCy }: PropsWithChildren<Props>) => {
  return (
    <tr
      data-cy={dataCy}
      className={cn(
        'border-lightGray8 dark:even:border-darkBlue dark:odd:border-darkBlue4 border-b dark:text-white dark:even:bg-darkBlue dark:odd:bg-darkBlue4',
        className
      )}
    >
      {children}
    </tr>
  );
};

export default TableRow;
