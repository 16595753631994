import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useLazyGetAuthQuickBooksLinkQuery, usePostDisconnectQuickBooksMutation } from 'apis/quickBooks.api';
import { QUICKBOOKS_TABS } from 'constants/quickBooks';
import { useOpen } from 'hooks/useOpen';
import { GetConnectionDetailsResponse } from 'interfaces/integrations/quickBooks.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Box, Button, ErrorModal, LoadingOverlay, WarningModal } from 'shared-components';
import Tabs from 'shared-components/Tabs';
import { successNotify } from 'utils/notifications';

interface Props {
  isConnect: boolean;
  connectionData?: GetConnectionDetailsResponse;
  triggerRefetch: () => void;
  isLoading: boolean;
  setIsReconnectionRequired: (isExpired: boolean) => void;
}

const QuickBooksConnectionHandler = ({
  isConnect,
  connectionData,
  triggerRefetch,
  isLoading,
  setIsReconnectionRequired,
}: Props) => {
  const { t } = useTranslation();
  const [isLocalReconnectionRequired, setLocalIsReconnectionRequired] = useState<boolean>(false);

  const [
    getAuthorizationQuickBooksUrl,
    { error: authorizationQuickBooksUrlError, isLoading: isConnectQuickBooksLoading },
  ] = useLazyGetAuthQuickBooksLinkQuery();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [isOpenWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useOpen();

  const [disconnectXero, { error: disconnectXeroAccountError, isLoading: isDisconnectXeroLoading }] =
    usePostDisconnectQuickBooksMutation();

  const errorDisconnectXeroData = (disconnectXeroAccountError as CommonError)?.data || {};
  const errorAuthorizationQuickBooksUrlData = (authorizationQuickBooksUrlError as CommonError)?.data || {};
  const errorData = errorAuthorizationQuickBooksUrlData || errorDisconnectXeroData;

  const handleConnectQuickBooks = async () => {
    try {
      const { data } = await getAuthorizationQuickBooksUrl();
      const link = data?.result || '';
      window.location.href = link;
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleDisconnectXero = async () => {
    try {
      await disconnectXero().unwrap();
      triggerRefetch();
      handleCloseWarningModal();
      successNotify(t('DisconnectedSuccessfully'));
    } catch (error) {
      handleCloseWarningModal();
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    if (isLocalReconnectionRequired) {
      setIsReconnectionRequired(true);
    }
  }, [isLocalReconnectionRequired, setIsReconnectionRequired]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('DoYouWantToDeleteYourQuickBooksConnection')}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleDisconnectXero}
      />
      <LoadingOverlay isLoading={isConnectQuickBooksLoading || isLoading} />
      {!isConnect || isLocalReconnectionRequired ? (
        <Box>
          <Button
            data-cy={'connect-quickbooks-button'}
            isLoading={isConnectQuickBooksLoading}
            onClick={handleConnectQuickBooks}
          >
            {t('ConnectQuickBooks')}
          </Button>
        </Box>
      ) : (
        <>
          <Box>
            <div className="grid grid-cols-2">
              <div className="col-span-1 flex flex-col justify-center">
                <p className="mb-2">{t('QuickBooksAccountConnected')}.</p>
                <p>{t('ConnectionExpiresInDays', { 0: connectionData?.result.expiresInDays })}.</p>
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <Button
                  className="float-right"
                  isLoading={isDisconnectXeroLoading}
                  data-cy="disconnect-quickbooks-button"
                  onClick={handleOpenWarningModal}
                >
                  {t('DisconnectQuickBooks')}
                </Button>
              </div>
            </div>
          </Box>
          <div className="pt-5">
            <Tabs
              componentProps={{
                setIsReconnectionRequired: (isReconnectionRequired: boolean) =>
                  setLocalIsReconnectionRequired(isReconnectionRequired),
              }}
              tabs={QUICKBOOKS_TABS}
            />
          </div>
        </>
      )}
    </>
  );
};

export default QuickBooksConnectionHandler;
