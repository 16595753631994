import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { Skeleton } from 'shared-components/index';
import { cn } from 'utils/global';

export interface Props {
  label: string;
  value?: string | number | null | ReactNode;
  labelClassName?: string;
  valueClassName?: string;
  displayAlways?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
  bodyClassName?: string;
  withColon?: boolean;
  isLoading?: boolean;
  skeletonClassName?: string;
  dataCy?: string;
}

const LabelWithValue = ({
  label,
  value,
  labelClassName,
  displayAlways,
  valueClassName,
  bodyClassName,
  children,
  withColon = true,
  isLoading,
  skeletonClassName,
  dataCy = 'label-with-value',
}: Props) => {
  const { t } = useTranslation();

  const sharedStyles = 'text-sm flex flex-col sm:flex-row mb-2 dark:text-gray';
  if (!value && !children && !displayAlways) {
    return null;
  }

  return (
    <div data-cy={dataCy} className={cn(sharedStyles, bodyClassName)}>
      <p className={cn('pr-2', labelClassName)}>
        {t(label)}
        {withColon ? ':' : ''}
      </p>
      {isLoading ? (
        <Skeleton className={cn('w-52 h-full', sharedStyles, bodyClassName, skeletonClassName)} />
      ) : (
        <>
          {value && <p className={cn('text-darkGray dark:text-white flex items-center', valueClassName)}>{value}</p>}
          {children}
        </>
      )}
    </div>
  );
};

export default LabelWithValue;
