import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetAllWithBaseInfoQuery } from 'apis/subscriptions.api';
import { TENANT_EDIT_CHECKBOX_FIELD, getTenantsEditInputFields } from 'constants/tenants';
import { FormContent } from 'shared-components';

interface Props {
  isLoading: boolean;
}

const TenantEditInputFields = ({ isLoading }: Props) => {
  const { data: subscriptionsData } = useGetAllWithBaseInfoQuery();
  const { result: subscriptionOptions } = subscriptionsData || {};
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const { dirtyFields } = useFormState({
    control,
  });

  const { t } = useTranslation();

  return (
    <div>
      <FormContent
        isLoading={isLoading}
        fields={getTenantsEditInputFields(subscriptionOptions)}
        register={register}
        errors={errors}
      />
      {dirtyFields.subscription ? (
        <p className="mt-5 text-sm text-error">{t('ChangeSubscriptionAttentionMessage')}</p>
      ) : null}
      <FormContent isLoading={isLoading} fields={TENANT_EDIT_CHECKBOX_FIELD} register={register} errors={errors} />
    </div>
  );
};

export default TenantEditInputFields;
