import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { usePostIsDocusignConnectedMutation, usePostProcessAuthentificationTokenMutation } from 'apis/docusign.api';
import { ROUTES } from 'constants/routes';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import DocusignActionsButton from 'page-components/integrations/docusign/DocusignActionsButton';
import DocusignConnectionHandler from 'page-components/integrations/docusign/DocusignConnectionHandler';
import { ErrorModal, LoadingOverlay } from 'shared-components';
import AppContentLayout from 'shared-components/AppContentLayout';

const Docusign = () => {
  const [isConnected, setIsConnected] = useState<boolean | null>(null);
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [checkDocusignConnection, { error: isConnectedError, isLoading: isConnectedLoading }] =
    usePostIsDocusignConnectedMutation();
  const [processAuthentificationToken] = usePostProcessAuthentificationTokenMutation();

  const { data: getConnectionErrorData } = (isConnectedError as CommonError) || {};
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const code = params.get('code');

  const checkConnection = async () => {
    try {
      if (code) {
        await processAuthentificationToken({ code }).unwrap();
      }
      const response = await checkDocusignConnection().unwrap();
      setIsConnected(response.result);
      navigate(ROUTES.docusign);
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    checkConnection();
  }, []);

  return (
    <>
      <AppContentLayout
        title="SigningRequests"
        customHeader={isConnected ? <DocusignActionsButton triggerRefetch={checkConnection} /> : ''}
      >
        <ErrorModal
          errorMessage={getConnectionErrorData?.error?.message}
          description={getConnectionErrorData?.error?.details}
          isOpen={isErrorModalOpen}
          handleClose={handleCloseErrorModal}
        />
        <LoadingOverlay isLoading={isConnectedLoading} isFixed />
        <div className="relative" data-cy="docusign-page">
          {!isConnectedLoading && isConnected !== null ? <DocusignConnectionHandler isConnect={isConnected} /> : ''}
        </div>
      </AppContentLayout>
    </>
  );
};

export default Docusign;
