import React, { useState, useRef } from 'react';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useDrop, XYCoord } from 'react-dnd';
import { Document, Page } from 'react-pdf';
import { v4 as uuidv4 } from 'uuid';

import { PLACEMENT_TYPE } from 'constants/docusign';
import { DEFAULT_PAGE_SIZE } from 'constants/global';
import { DroppedItem, SignerBlock } from 'interfaces/integrations/docusign.interface';
import { PageSize } from 'interfaces/pdf-viewer';
import DocusignDroppedItem from 'page-components/integrations/docusign/DocusignDroppedItem';
import { cn } from 'utils/global';

interface Props {
  fileUrl: string;
  className?: string;
  signerBlocks?: any;
  droppedItems?: DroppedItem[];
  setDroppedItems?: React.Dispatch<React.SetStateAction<DroppedItem[]>>;
}

const PDFViewer = ({ fileUrl, className, signerBlocks = [], droppedItems = [], setDroppedItems }: Props) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const ref = useRef<HTMLDivElement | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function onPageLoadSuccess({ width, height }: { width: number; height: number }): void {
    setPageSize({ width, height });
  }

  const getPageNumberAndRect = (offset: XYCoord, containerRect: DOMRect, pageElements: NodeListOf<Element>) => {
    for (let i = 0; i < pageElements.length; i++) {
      const pageRect = pageElements[i].getBoundingClientRect();
      if (offset.y >= pageRect.top - containerRect.top && offset.y <= pageRect.bottom - containerRect.top) {
        return { pageNumber: i + 1, targetPageRect: pageRect };
      }
    }
    return { pageNumber: 1, targetPageRect: null };
  };

  const calculatePosition = (offset: XYCoord, targetPageRect: DOMRect, pageSize: PageSize) => {
    const x = offset.x - targetPageRect.left;
    const y = offset.y - targetPageRect.top;
    const scaleX = pageSize.width / targetPageRect.width;
    const scaleY = pageSize.height / targetPageRect.height;
    return {
      signXPosition: Math.round(x * scaleX),
      signYPosition: Math.round(y * scaleY),
    };
  };

  const createDroppedItem = (
    item: DroppedItem,
    signXPosition: number,
    signYPosition: number,
    pageNumber: number,
    signerBlocks: any
  ) => {
    const blockColor = signerBlocks.find((block: SignerBlock) => block.id === item.blockId)?.color || item.borderColor;

    return {
      ...item,
      id: item.id || uuidv4(),
      x: signXPosition,
      y: signYPosition,
      pageNumber,
      borderColor: blockColor,
      placementType: getPlacementType(item.label),
    };
  };

  const [, drop] = useDrop(
    () => ({
      accept: 'SIGNER_ITEM',
      drop: (item: DroppedItem, monitor) => {
        if (!setDroppedItems) {
          return;
        }

        const offset = monitor.getSourceClientOffset();
        if (!offset || !ref.current) {
          return;
        }

        const containerRect = ref.current.getBoundingClientRect();
        const pageElements = ref.current.querySelectorAll('.react-pdf__Page');

        const { pageNumber, targetPageRect } = getPageNumberAndRect(offset, containerRect, pageElements);
        if (!targetPageRect) {
          return;
        }

        const { signXPosition, signYPosition } = calculatePosition(offset, targetPageRect, pageSize);
        const newItem = createDroppedItem(item, signXPosition, signYPosition, pageNumber, signerBlocks);

        setDroppedItems((prevItems = []) => {
          const updatedItems = item.id
            ? prevItems.map((prevItem) => (prevItem.id === item.id ? newItem : prevItem))
            : [...prevItems, newItem];
          return updatedItems;
        });
      },
    }),
    [signerBlocks, setDroppedItems, pageSize]
  );

  const getPlacementType = (label: string): number => {
    return PLACEMENT_TYPE[label] || 1;
  };

  drop(ref);

  return (
    <div
      ref={ref}
      data-cy="pdf-viewer"
      className={cn('h-[80vh] overflow-y-auto p-4 max-w-full pdf-container', className)}
    >
      <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (_el, index) => (
          <div key={index} className="relative">
            <Page key={index} pageNumber={index + 1} width={800} onLoadSuccess={onPageLoadSuccess} className="mb-3" />
            {droppedItems
              .filter((item) => item.pageNumber === index + 1)
              .map((item) => (
                <DocusignDroppedItem
                  key={item.id}
                  item={item}
                  signerBlocks={signerBlocks}
                  setDroppedItems={setDroppedItems}
                  pageSize={pageSize}
                />
              ))}
          </div>
        ))}
      </Document>
    </div>
  );
};

export default PDFViewer;
