import { FieldTypes } from 'interfaces/shared.interface';
import { cn } from 'utils/global';

interface Props {
  isEnabled: boolean;
  onToggle: VoidFunction;
  dataCy?: string;
}
const ToggleSwitch = ({ onToggle, isEnabled, dataCy }: Props) => {
  return (
    <div className="flex">
      <label
        htmlFor="toggle"
        className={cn('flex items-center cursor-pointer bg-blue rounded-3xl', {
          'hover:bg-blue2': isEnabled,
          'bg-lightGray2 hover:bg-gray3 dark:bg-darkBlue2 dark:border-[1px] dark:border-darkGray2': !isEnabled,
        })}
      >
        <div className="relative" data-cy={dataCy}>
          <input id="toggle" type={FieldTypes.CHECKBOX} className="sr-only" checked={isEnabled} onChange={onToggle} />
          <div className="block w-12 h-7" />
          <div
            className={cn('transition duration-200 absolute left-1 top-1 bg-white w-5 h-5 rounded-full', {
              'transform translate-x-5': isEnabled,
              'dark:bg-darkGray2': !isEnabled,
            })}
          />
        </div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
