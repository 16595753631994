import { useEffect } from 'react';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { SupportRequestChatProvider } from 'contexts/SupportRequestChatContext';
import { getAuthState } from 'modules/auth/action';
import { selectIsAuth } from 'modules/auth/selector';
import { useAppDispatch, useAppSelector } from 'modules/store';
import {
  ActivateEmail,
  Dashboard,
  ForgotPassword,
  ManagementPage,
  NotFound,
  Overview,
  ResetPassword,
  SignIn,
  ConfirmEmail,
  SignUp,
  TwoFactorAuthenticator,
  Roles,
  Users,
  Tenants,
  TenantDetails,
  SubscriptionsAdmin,
  SubscriptionsTenant,
  PrivacyPolicy,
  AuditLogs,
  EmailTemplates,
  CreateEmailTemplate,
  SendEmailTemplate,
  EditEmailTemplate,
  CloneEmailTemplate,
  Samples,
  SupportTickets,
  SupportTicketChat,
  GDPR,
  TenantDetailsPage,
  OrganizationUnits,
  Invoices,
  CompaniesHouse,
  InvoiceDetailsPage,
  TrueLayer,
  TrueLayerCardDetailsPage,
  TrueLayerAccountDetailsPage,
  GoCardless,
  Xero,
  SupportRequests,
  Sage,
  QuickBooks,
  PayPal,
  CodatAccounting,
  SageContactDetailsPage,
  SageInvoiceDetailsPage,
  CustomerDetailsPage,
  InvoiceQuickBooksDetailsPage,
  TyplessDocumentTypesPage,
  TyplessDocumentDetailsTypesPage,
  TyplessDocumentParsingPage,
  OSAuditLogs,
  CypressTestsPage,
} from 'pages';
import Docusign from 'pages/integrations/Docusign';
import DocusignDocumentEditorPage from 'pages/integrations/DocusignDocumentEditorPage';
import { ApplicationLayout, AuthLayout, Button, ProtectedRoute } from 'shared-components';

import TermsAndConditions from './pages/TermsAndConditions';
import AppContentLayout from './shared-components/AppContentLayout';

// TODO - EGO-617, remove this Test component when tenant creating will be implemented
const Test = () => <Button>Edit</Button>;

export const UNPROTECTED_ROUTES = [
  { path: ROUTES.signIn, element: SignIn },
  { path: ROUTES.signUp, element: SignUp },
  { path: ROUTES.forgotPassword, element: ForgotPassword },
  { path: ROUTES.activateEmail, element: ActivateEmail },
  { path: ROUTES.resetPassword, element: ResetPassword },
  { path: ROUTES.confirmEmail, element: ConfirmEmail },
  { path: ROUTES.twoFactorAuthenticator, element: TwoFactorAuthenticator },
  { path: ROUTES.cypressTestsPage, element: CypressTestsPage },
];

export const PROTECTED_SHARED_ROUTES = [
  { path: ROUTES.dashboard, element: Dashboard },
  { path: ROUTES.overview, element: Overview },
  { path: ROUTES.roles, element: Roles, hideLayout: true },
  { path: ROUTES.users, element: Users, hideLayout: true },
  { path: ROUTES.managementPage, element: ManagementPage },
  { path: ROUTES.auditLogs, element: AuditLogs, pageTitle: 'AuditLogs' },
  { path: ROUTES.samples, element: Samples, pageTitle: 'SamplePage' },
  { path: ROUTES.supportTicketsChat, element: SupportTicketChat, hideLayout: true },
  { path: ROUTES.companiesHouse, element: CompaniesHouse, pageTitle: 'CompaniesHouse' },
  { path: ROUTES.trueLayer, element: TrueLayer, pageTitle: 'TrueLayer' },
  { path: `${ROUTES.xero}/*`, element: Xero, pageTitle: 'Xero' },
  {
    path: `${ROUTES.codatAccounting}/*`,
    element: CodatAccounting,
    pageTitle: 'CodatAccounting',
  },
  {
    path: `${ROUTES.goCardless}/*`,
    element: GoCardless,
    pageTitle: 'Repayments',
    titlePrefix: 'GoCardless: ',
  },
  {
    path: `${ROUTES.sage}/*`,
    element: Sage,
    pageTitle: 'Sage',
  },
  { path: `${ROUTES.quickBooks}/*`, element: QuickBooks, pageTitle: 'QuickBooks' },
  { path: `${ROUTES.payPal}/*`, element: PayPal, pageTitle: 'PayPal' },
  { path: `${ROUTES.docusign}/*`, element: Docusign },
];

export const PROTECTED_ADMIN_ROUTES = [
  { path: ROUTES.tenants, element: Tenants, pageTitle: 'Tenants' },
  { path: ROUTES.tenantUsers, element: Users, hideLayout: true },
  { path: ROUTES.tenantRoles, element: Roles, hideLayout: true },
  { path: ROUTES.gdprAdmin, element: GDPR, pageTitle: 'TenantRequestsToBeForgotten' },
  { path: ROUTES.supportTickets, element: SupportTickets, pageTitle: 'SupportTickets' },
  {
    path: ROUTES.tenantDetails,
    element: TenantDetails,
    pageTitle: 'TenantDetails',
    headerClassName: 'px-7',
    customHeader: Test,
  },
  {
    path: ROUTES.emailTemplates,
    element: EmailTemplates,
    pageTitle: 'EmailTemplates',
  },
  { path: ROUTES.createEmailTemplate, element: CreateEmailTemplate, pageTitle: 'CreateEmailTemplate' },
  { path: ROUTES.sendEmailTemplate, element: SendEmailTemplate, pageTitle: 'SendEmail' },
  { path: ROUTES.sendEmailTemplateById, element: SendEmailTemplate, pageTitle: 'SendEmail' },
  { path: ROUTES.sendEmailTemplate, element: SendEmailTemplate, pageTitle: 'SendEmail' },
  { path: ROUTES.editEmailTemplate, element: EditEmailTemplate, pageTitle: 'EditEmailTemplate' },
  { path: ROUTES.cloneEmailTemplate, element: CloneEmailTemplate, pageTitle: 'CloneEmailTemplate' },
  { path: ROUTES.subscriptions, element: SubscriptionsAdmin, pageTitle: 'Subscriptions' },
];

export const PROTECTED_TENANT_ROUTES = [
  { path: ROUTES.tenantSubscription, element: SubscriptionsTenant, pageTitle: 'Subscription' },
  { path: ROUTES.supportRequests, element: SupportRequests, pageTitle: 'SupportRequests' },
  { path: ROUTES.tenantDetailsPage, element: TenantDetailsPage, pageTitle: 'TenantDetails' },
  { path: ROUTES.gdprTenant, element: GDPR, pageTitle: 'UserRequestsToBeForgotten' },
  { path: ROUTES.organizationUnits, element: OrganizationUnits, pageTitle: 'OrganizationUnits' },
  { path: ROUTES.invoices, element: Invoices, hideLayout: true },
  { path: ROUTES.invoiceDetailsPage, element: InvoiceDetailsPage, hideLayout: true },
  {
    path: ROUTES.trueLayerAccountDetails,
    element: TrueLayerAccountDetailsPage,
    pageTitle: 'TrueLayerAccountDetails',
  },
  {
    path: ROUTES.trueLayerCardDetails,
    element: TrueLayerCardDetailsPage,
    pageTitle: 'TrueLayerCardDetails',
  },
  { path: ROUTES.customerDetailsPage, element: CustomerDetailsPage, hideLayout: true },
  { path: ROUTES.invoiceQuickBooksDetailsPage, element: InvoiceQuickBooksDetailsPage, hideLayout: true },
  {
    path: ROUTES.sageInvoiceDetails,
    element: SageInvoiceDetailsPage,
    pageTitle: 'InvoiceDetails',
  },
  {
    path: ROUTES.sageContactDetails,
    element: SageContactDetailsPage,
    pageTitle: 'ContactDetails',
  },
  {
    path: ROUTES.typlessDocumentTypesPage,
    element: TyplessDocumentTypesPage,
    pageTitle: 'DocumentTypes',
  },
  { path: ROUTES.typlessTrainDocumentPage, element: TyplessDocumentDetailsTypesPage, hideLayout: true },
  { path: ROUTES.typlessDocumentParsingPage, element: TyplessDocumentParsingPage, pageTitle: 'DocumentParsing' },
  { path: ROUTES.osAuditLogs, element: OSAuditLogs, hideLayout: true },
  { path: ROUTES.docusignNewDocument, element: DocusignDocumentEditorPage, pageTitle: 'DocumentEditor' },
  { path: ROUTES.quickBooksCallback, element: QuickBooks, pageTitle: 'QuickBooks' },
  { path: ROUTES.xeroCallback, element: Xero, pageTitle: 'Xero' },
];

export const SHARED_ROUTES = [
  { path: ROUTES.notFound, element: NotFound },
  {
    path: ROUTES.termsAndConditions,
    element: TermsAndConditions,
  },
  { path: ROUTES.privacyPolicy, element: PrivacyPolicy },
];

const Router = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuth);

  useEffect(() => {
    if (isAuth === null) {
      dispatch(getAuthState());
    }
  }, [dispatch, isAuth]);

  if (isAuth === null) {
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.default} element={<Navigate to={isAuth ? ROUTES.dashboard : ROUTES.signIn} />} />

        {SHARED_ROUTES.map(({ path, element: Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}

        <Route element={<ProtectedRoute isAllowed={!isAuth} redirectPath={ROUTES.dashboard} />}>
          <Route element={<AuthLayout />}>
            {UNPROTECTED_ROUTES.map(({ path, element: Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Route>
        </Route>

        <Route element={<ProtectedRoute isAllowed={isAuth} redirectPath={ROUTES.signIn} />}>
          <Route
            element={
              <SupportRequestChatProvider>
                <ApplicationLayout />
              </SupportRequestChatProvider>
            }
          >
            {PROTECTED_SHARED_ROUTES.map(({ path, element: Component, hideLayout, pageTitle, titlePrefix }) => (
              <Route
                key={path}
                path={path}
                element={
                  hideLayout ? (
                    <Component />
                  ) : (
                    <AppContentLayout title={pageTitle} titlePrefix={titlePrefix}>
                      <Component />
                    </AppContentLayout>
                  )
                }
              />
            ))}
          </Route>
        </Route>

        <Route
          element={
            <ProtectedRoute
              isAllowed={isAuth}
              allowedRoles={['admin']}
              redirectPath={isAuth ? ROUTES.notFound : ROUTES.signIn}
            />
          }
        >
          <Route element={<ApplicationLayout />}>
            {PROTECTED_ADMIN_ROUTES.map(
              ({ path, element: Component, pageTitle, hideLayout, customHeader: CustomHeader }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    hideLayout ? (
                      <Component />
                    ) : (
                      <AppContentLayout title={pageTitle} customHeader={CustomHeader && <CustomHeader />}>
                        <Component />
                      </AppContentLayout>
                    )
                  }
                />
              )
            )}
          </Route>
        </Route>

        <Route
          element={
            <ProtectedRoute
              isAllowed={isAuth}
              allowedRoles={['tenant']}
              redirectPath={isAuth ? ROUTES.notFound : ROUTES.signIn}
            />
          }
        >
          <Route element={<ApplicationLayout />}>
            {PROTECTED_TENANT_ROUTES.map(({ path, element: Component, hideLayout, pageTitle }) => (
              <Route
                key={path}
                path={path}
                element={
                  hideLayout ? (
                    <Component />
                  ) : (
                    <AppContentLayout title={pageTitle}>
                      <Component />
                    </AppContentLayout>
                  )
                }
              />
            ))}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
