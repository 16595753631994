import { FC, ReactNode, useState } from 'react';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { TableColumn } from 'interfaces/shared.interface';
import { TableData, TableHead, TableRow } from 'shared-components/index';
import { cn } from 'utils/global';

import TableSkeleton from './TableSkeleton';

interface Props {
  columns?: TableColumn[];
  children: ReactNode;
  handleSetTabName?: (value: string) => void;
  sortingType?: string;
  sortingColumnId?: string | null;
  className?: string;
  dataCy?: string;
  isTableEmpty?: boolean;
  isLoading: boolean;
  isTableHeadSkeleton?: boolean;
  showAddButton?: boolean;
  addButtonText?: string;
  onAddRow?: () => void;
  addButtonIcon?: IconDefinition;
  isSelectAllCheckbox?: boolean;
  selectAll?: boolean;
  handleSelectAll?: () => void;
}

const Table: FC<Props> = ({
  columns,
  children,
  sortingType,
  sortingColumnId,
  handleSetTabName,
  className,
  dataCy,
  isTableEmpty,
  isLoading,
  isTableHeadSkeleton,
  showAddButton,
  addButtonText,
  addButtonIcon,
  onAddRow,
  isSelectAllCheckbox,
  selectAll,
  handleSelectAll,
}) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState<ReactNode[]>([]);

  const handleAddRow = () => {
    if (onAddRow) {
      onAddRow();
    } else {
      setRows([
        ...rows,
        <TableRow key={`row-${rows.length}`}>
          {columns?.map((_col) => (
            <TableData key={_col.columnId}>
              <input type="text" className="w-full h-full px-2 py-1" />
            </TableData>
          ))}
        </TableRow>,
      ]);
    }
  };

  return (
    <table data-cy={dataCy} className={cn('bg-white dark:bg-darkBlue4 min-w-full', className)}>
      {isTableHeadSkeleton ? (
        <TableSkeleton columns={columns?.length} />
      ) : (
        <TableHead
          dataCy="table-head"
          columns={columns}
          handleSetTabName={handleSetTabName}
          sortingType={sortingType}
          sortingColumnId={sortingColumnId}
          showAddButton={showAddButton}
          addButtonText={addButtonText}
          addButtonIcon={addButtonIcon}
          onAddRow={handleAddRow}
          isSelectAllCheckbox={isSelectAllCheckbox}
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
        />
      )}
      <tbody className="text-darkGray">
        {isLoading && <TableSkeleton columns={columns?.length} />}{' '}
        {!isLoading && isTableEmpty ? (
          <tr>
            <td colSpan={columns?.length} className="text-center py-4 dark:text-white">
              {t('NoData')}
            </td>
          </tr>
        ) : (
          children
        )}
      </tbody>
    </table>
  );
};

export default Table;
