import { useMemo } from 'react';

import { useGetCurrentLoginInformationsQuery } from 'apis/user.api';

enum USER_ROLES {
  admin = 'admin',
  tenant = 'tenant',
}

const useUserRole = () => {
  const { data: currentLoginInfoData } = useGetCurrentLoginInformationsQuery();

  const userRole = useMemo(
    () => (currentLoginInfoData?.tenant ? USER_ROLES.tenant : USER_ROLES.admin),
    [currentLoginInfoData]
  );

  return {
    userRole,
    isAdmin: userRole === USER_ROLES.admin,
    isTenant: userRole === USER_ROLES.tenant,
  };
};

export default useUserRole;
