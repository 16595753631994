import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import warningSVG from 'assets/svg/warning.svg';
import { ButtonVariants } from 'constants/common';
import { Button, Modal } from 'shared-components';

export interface Props {
  warningMessage?: string;
  isOpen: boolean;
  handleClose: VoidFunction;
  handleConfirm: VoidFunction | undefined;
  description?: string | string[];
  isLoading?: boolean;
}
const WarningModal: FC<Props> = ({ isOpen, handleClose, handleConfirm, description, warningMessage, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Modal dataCy="warning-modal" isOpen={isOpen} icon={warningSVG} isError hideCross onClose={handleClose}>
      <div className="text-2xl font-semibold font-sans text-center">{warningMessage}</div>
      {description && <div className="text-center mt-4">{description}</div>}

      <div className="flex justify-center mt-5">
        <Button dataCy="warning-modal-confirm-button" onClick={handleConfirm} className="mr-4" isLoading={isLoading}>
          {t('Yes')}
        </Button>
        <Button className="hover:bg-gray2" onClick={handleClose} variant={ButtonVariants.SECONDARY}>
          {t('Cancel')}
        </Button>
      </div>
    </Modal>
  );
};

export default WarningModal;
