import { useEffect } from 'react';

import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faCalendarXmark, faPencil } from '@fortawesome/free-solid-svg-icons';
import { ColorPicker, ColorPickerChangeEvent } from 'primereact/colorpicker';
import { FormProvider, useForm } from 'react-hook-form';

import { DOCUSIGN_SIGNERS_FORM_FIELD } from 'constants/docusign';
import {
  BaseAddSignersFormValue,
  DOCUSIGN_SIGNERS_FORM_FIELDS,
  SignerBlock,
} from 'interfaces/integrations/docusign.interface';
import { FormContent } from 'shared-components';
import { cn } from 'utils/global';

import DragAndDropSignersBlock from './DragAndDropSignersBlock';

interface Props {
  block: SignerBlock;
  updateSignerBlock: (id: string | number, data: Partial<SignerBlock>) => void;
  id: number | string;
  isActive: boolean;
  color: string;
  setColor: (color: string) => void;
  toggleBlock: () => void;
  isCheckboxActive: boolean;
  numeration: number;
  updateBlockColor: (color: string) => void;
}

const SignersBlockItem = ({
  block,
  updateSignerBlock,
  id,
  isActive,
  color,
  setColor,
  toggleBlock,
  isCheckboxActive,
  numeration,
  updateBlockColor,
}: Props) => {
  const { ...addSIgnersTypeMethods } = useForm<BaseAddSignersFormValue>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      [DOCUSIGN_SIGNERS_FORM_FIELDS.SIGNERS_NAME]: block.fullName,
      [DOCUSIGN_SIGNERS_FORM_FIELDS.SIGNERS_EMAIL]: block.email,
    },
  });

  const {
    register,
    formState: { errors },
    watch,
  } = addSIgnersTypeMethods;

  const watchName = watch(DOCUSIGN_SIGNERS_FORM_FIELDS.SIGNERS_NAME);
  const watchEmail = watch(DOCUSIGN_SIGNERS_FORM_FIELDS.SIGNERS_EMAIL);

  const handleColorChange = (e: ColorPickerChangeEvent) => {
    const newColor = `#${e.value}`;
    setColor(newColor);
    updateBlockColor(newColor);
  };

  useEffect(() => {
    updateSignerBlock(block.id, {
      fullName: watchName,
      email: watchEmail,
    });
  }, [watchName, watchEmail]);

  return (
    <div
      className={cn(
        '!bg-white dark:!bg-darkBlue4 mt-[1px] cursor-pointer p-3 hover:!bg-gray13 dark:hover:!bg-darkBlue2',
        {
          '!bg-lightBlue2 dark:!bg-blue5 hover:!bg-lightBlue2 dark:hover:!bg-blue5': isActive,
        }
      )}
      onClick={toggleBlock}
    >
      <div className="flex items-center relative">
        {isCheckboxActive && (
          <div className="flex items-center justify-center absolute top-0 left-1.5 w-8 h-8 rounded-full border-2 border-darkGray dark:border-white text-darkGray dark:text-white text-sm font-bold">
            {numeration}
          </div>
        )}
        <ColorPicker className="ml-2 mr-5" value={color} onChange={handleColorChange} />
        <FormProvider {...addSIgnersTypeMethods}>
          <form data-cy="create-request-modal-form" className="w-full">
            <FormContent fields={DOCUSIGN_SIGNERS_FORM_FIELD()} register={register} errors={errors} />
          </form>
        </FormProvider>
      </div>
      <div className="flex justify-between relative">
        <DragAndDropSignersBlock icon={faPencil} label="Signature" borderColor={color} blockId={id} />
        <DragAndDropSignersBlock icon={faCalendarXmark} label="Date" borderColor={color} blockId={id} />
        <DragAndDropSignersBlock icon={faUser} label="Initials" borderColor={color} blockId={id} />
      </div>
    </div>
  );
};

export default SignersBlockItem;
