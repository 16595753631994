import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { BoxVariants, BoxVariantsStyles } from 'constants/common';
import { cn } from 'utils/global';

interface Props {
  dataCy?: string;
  children: ReactNode;
  className?: string;
  variant?: BoxVariants;
  title?: string;
}

const Box = ({ dataCy, children, className, title, variant = BoxVariants.MAIN }: Props) => {
  const { t } = useTranslation();

  return (
    <div data-cy={dataCy} className={cn('p-3.5 rounded-[3px]', BoxVariantsStyles[variant], className)}>
      {title && <h3 className="text-2xl font-medium mb-3">{t(title)}</h3>}
      {children}
    </div>
  );
};

export default Box;
