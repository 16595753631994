import { TabItem } from 'interfaces/shared.interface';

export const SUBSCRIPTION_TABS: TabItem[] = [
  {
    dataCy: 'subscription-information-button',
    id: 'subscription-information',
    title: 'SubscriptionInformation',
    // component: MembersTab,
  },
  {
    dataCy: 'payment-history-tab-button',
    id: 'payment-history',
    title: 'PaymentHistory',
    // component: RolesTab,
  },
];
