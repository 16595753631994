import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';

import { useUploadInvoiceMutation } from 'apis/invoices.api';
import { useGetAllTrainedQuery, usePostParseDocumentMutation } from 'apis/typless.apis';
import { useOpen } from 'hooks/useOpen';
import { CommonError, FileData, OptionType } from 'interfaces/shared.interface';
import { Box, Button, ErrorModal, LoadingOverlay, Select } from 'shared-components';

import TyplessDocumentDetailsTypesPage from './TyplessDocumentTypesDetailsPage';

const TyplessDocumentParsingPage = () => {
  const { t } = useTranslation();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [isDocumentTypeSelected, setIsDocumentTypeSelected] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [fileData, setFileData] = useState<FileData | null>(null);
  const { data, isLoading, isFetching, error } = useGetAllTrainedQuery();
  const [parseDocument, { isLoading: parseLoading }] = usePostParseDocumentMutation();
  const [uploadInvoice] = useUploadInvoiceMutation();

  const errorData = (error as CommonError) || {};

  const selectOptions = useMemo(() => {
    return (
      data?.result.map((item) => ({
        label: item.status === 3 ? `${item.name || ''} (${t('InTraining')})` : item.name || '',
        value: item.id ? item.id.toString() : '',
      })) || []
    );
  }, [data]);

  const handleSelectChange = (newValue: SingleValue<OptionType> | MultiValue<OptionType>) => {
    if (!Array.isArray(newValue)) {
      setSelectedOption(newValue as SingleValue<OptionType>);
      setIsButtonDisabled(false);
    }
  };

  const resetState = () => {
    setSelectedOption(null);
    setIsDocumentTypeSelected(false);
    setFileData(null);
  };

  const handleContinue = () => {
    setIsDocumentTypeSelected(true);
    setIsButtonDisabled(true);
  };

  const handleParse = async () => {
    if (!fileData || !selectedOption) {
      return;
    }
    try {
      await parseDocument({
        documentBase64String: fileData.fileBase64,
        documentName: fileData.fileName,
        documentTypeId: Number(selectedOption.value),
      });
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleUploadInvoice = async (invoiceData: any) => {
    try {
      await uploadInvoice(invoiceData);
      resetState();
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleButtonClick = () => {
    if (isDocumentTypeSelected) {
      handleParse();
    } else {
      handleContinue();
    }
  };

  const handleFileDataLogic = (newFileData: FileData | null) => {
    setFileData(newFileData);
  };

  useEffect(() => {
    if (error) {
      handleOpenErrorModal();
    }
  }, [error, handleOpenErrorModal]);

  const buttonText = isDocumentTypeSelected ? t('Parse') : t('Continue');

  return (
    <div data-cy="typless-document-parsing-page">
      <ErrorModal
        errorMessage={errorData?.data?.error?.message}
        description={errorData?.data?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />

      {isFetching || isLoading ? (
        <div>
          <Box className="mt-10 h-28">
            <LoadingOverlay className="!relative" isLoading={true} />
          </Box>
        </div>
      ) : (
        <div className="mt-2">
          <Box className="flex rounded-b-none">
            <Select
              className="flex sm:w-1/4 w-full"
              isSearchable={true}
              options={selectOptions}
              onChange={handleSelectChange}
              value={selectedOption}
              isOptionDisabled={(option) => {
                const trainingOption = data?.result.find((item) => item.id.toString() === option.value);
                return trainingOption?.status === 3;
              }}
              isDisabled={!data}
              menuPlacement="bottom"
              placeholder={t('SelectDocumentType')}
            />
            <Button
              className="ml-4 flex items-center"
              isLoading={isLoading}
              disabled={!selectedOption || isButtonDisabled}
              data-cy={'typless-parsing-continue-parse-button'}
              onClick={handleButtonClick}
            >
              {buttonText}
            </Button>
          </Box>
          {isDocumentTypeSelected && (
            <>
              <LoadingOverlay isFixed isLoading={parseLoading} />
              <TyplessDocumentDetailsTypesPage
                isDocumentParsing
                pdfDocummentId={selectedOption?.value}
                successfullMessage
                uploadDocumentLogic
                isDocumentTypeSelected={() => setIsDocumentTypeSelected(false)}
                fileDataLogic={handleFileDataLogic}
                onUploadInvoice={handleUploadInvoice}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TyplessDocumentParsingPage;
