import { useTranslation } from 'react-i18next';

import { useLazyGetAuthorizationXeroUrlQuery, usePostDisconnectXeroAccountMutation } from 'apis/xero.api';
import { XERO_TABS } from 'constants/xero';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { Box, Button, ErrorModal, WarningModal } from 'shared-components';
import Tabs from 'shared-components/Tabs';
import { successNotify } from 'utils/notifications';

interface Props {
  isConnect: boolean;
  triggerRefetch: () => void;
}

const XeroConnectionHandler = ({ isConnect, triggerRefetch }: Props) => {
  const { t } = useTranslation();
  const [getAuthorizationXeroUrl, { error: authorizationXeroUrlError, isLoading: isAuthorizationXeroLoading }] =
    useLazyGetAuthorizationXeroUrlQuery();
  const [isOpenWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [disconnectXero, { error: disconnectXeroAccountError, isLoading: isDisconnectXeroLoading }] =
    usePostDisconnectXeroAccountMutation();

  const errorDisconnectXeroData = (disconnectXeroAccountError as CommonError)?.data || {};
  const errorAuthorizationXeroUrlData = (authorizationXeroUrlError as CommonError)?.data || {};

  const errorData = errorDisconnectXeroData || errorAuthorizationXeroUrlData;

  const handleConnectXero = async () => {
    try {
      const { data } = await getAuthorizationXeroUrl();
      const link = data?.result.xeroCallbackUrl || '';
      window.location.href = link;
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleDisconnectXero = async () => {
    try {
      await disconnectXero().unwrap();
      triggerRefetch();
      handleCloseWarningModal();
      successNotify(t('DisconnectedSuccessfully'));
    } catch (error) {
      handleCloseWarningModal();
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('AreYouSureYouWantToDisconnectFromXero')}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleDisconnectXero}
      />
      {!isConnect ? (
        <Box>
          <Button data-cy={'connect-xero-button'} isLoading={isAuthorizationXeroLoading} onClick={handleConnectXero}>
            {t('ConnectXero')}
          </Button>
        </Box>
      ) : (
        <>
          <Box>
            <div className="grid grid-cols-2">
              <div className="col-span-1 flex flex-col justify-center">
                <p className="mb-2">{t('XeroConnected')}.</p>
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <Button
                  className="float-right"
                  isLoading={isDisconnectXeroLoading}
                  data-cy="disconnect-xero-button"
                  onClick={handleOpenWarningModal}
                >
                  {t('DisconnectXero')}
                </Button>
              </div>
            </div>
          </Box>
          <div className="pt-5">
            <Tabs tabs={XERO_TABS} />
          </div>
        </>
      )}
    </>
  );
};

export default XeroConnectionHandler;
