import { CommonResponse } from 'interfaces/shared.interface';
import {
  BaseSubscriptionsResponse,
  CreateSubscriptionPayload,
  SubscriptionsResponse,
  SubscriptionPaymentHistoryResponse,
  GetSubscriptionByIdResponse,
  EditSubscriptionPayload,
} from 'interfaces/subscriptions-admin.interface';

import { coreSplitApis } from './core.api';

export const subscriptions = coreSplitApis.enhanceEndpoints({ addTagTypes: ['Subscriptions'] }).injectEndpoints({
  endpoints: (build) => ({
    getSubscriptions: build.query<SubscriptionsResponse, string>({
      query: (params) => ({
        url: `SubscriptionManager/GetAll?${params}`,
        method: 'GET',
      }),
      providesTags: ['Subscriptions'],
    }),
    deleteSubscription: build.mutation<void, number>({
      query: (subscriptionId) => ({
        url: `SubscriptionManager/Delete?subscriptionId=${subscriptionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Subscriptions']),
    }),
    getSubscriptionPaymentsHistory: build.query<SubscriptionPaymentHistoryResponse, string>({
      query: (params) => ({
        url: `SubscriptionPayment/GetHistory?${params}`,
        method: 'GET',
      }),
    }),
    getAllWithBaseInfo: build.query<BaseSubscriptionsResponse, void | string>({
      query: (params) => ({
        url: `SubscriptionManager/GetAllWithBaseInfo` + (params ? `?${params}` : ''),
        method: 'GET',
      }),
    }),
    createSubscription: build.mutation<CommonResponse, CreateSubscriptionPayload>({
      query: (body) => ({
        url: 'SubscriptionManager/Create',
        method: 'POST',
        data: body,
      }),
    }),
    getSubscriptionById: build.query<GetSubscriptionByIdResponse, string>({
      query: (subscriptionId) => ({
        url: `SubscriptionManager/GetById?Id=${subscriptionId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    updateSubscription: build.mutation<CommonResponse, EditSubscriptionPayload>({
      query: (body) => ({
        url: 'SubscriptionManager/Update',
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Subscriptions']),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetAllWithBaseInfoQuery,
  useGetSubscriptionsQuery,
  useDeleteSubscriptionMutation,
  useGetSubscriptionPaymentsHistoryQuery,
  useCreateSubscriptionMutation,
  useGetSubscriptionByIdQuery,
  useUpdateSubscriptionMutation,
} = subscriptions;
