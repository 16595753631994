import { uk, enGB, enUS, Locale } from 'date-fns/locale';

import { OptionType } from 'interfaces/shared.interface';

export enum LocalStorageKeys {
  IS_AUTH = 'isAuth',
}

export enum Currency {
  USD = 'USD',
  GBP = 'GBP',
  UAH = 'UAH',
}

export const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const PASSWORD_PATTERN = /^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!$#%@]).*$/;

export const STRING_WITHOUT_SPACES_PATTERN = /\s/g;

export const STRING_WITHOUT_TAGS_PATTERN = /<\/?[^>]+(>|$)/g;

export const STRING_WITHOUT_VARIABLES_PATTERN = /#{(.*?)}#/g;

export const WRONG_MESSAGE = 'SomethingWentWrong';

export const TWO_FACTOR_AUTHENTICATOR_PATTERN = /^\d{6}$/;

export const BACK_BUTTON = 'Back';

export const ClOSE_BUTTON = 'Close';

export const SUBMIT_BUTTON = 'Submit';

export const PAGINATION_SELECT_OPTIONS: OptionType[] = [
  { label: '5', value: '5' },
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
  { value: '250', label: '250' },
  { value: '500', label: '500' },
];

export const SORTING_DESC = 'DESC';
export const SORTING_ASC = 'ASC';

export const DAY_FORMAT_WITH_SLASHES = 'MM/dd/yyyy';
export const DAY_MONTH_YEAR_FORMAT_WITH_SLASHES = 'dd/MM/yyyy';
export const DAY_FORMAT_WITH_DAY_NAME = 'EEEE, d MMMM yyyy HH:mm';
export const DAY_FORMAT_WITH_DAY_NAME_AND_FORMAT_TIME = 'EEEE, d MMMM yyyy h:MM a';
export const DAY_FORMAT_WITH_MONTH_NAME = 'd MMMM yyyy HH:mm';
export const DAY_FORMAT_WITH_TIME = 'dd/MM/yyyy HH:mm:ss';
export const DAY_FORMAT_WITH_DASH_WITH_TIME = 'dd-MM-yyyy HH:mm:ss';
export const DAY_FORMAT_WITH_TIME_WITHOUT_SECONDS = 'dd/MM/yyyy HH:mm';
export const DAY_FORMAT_WITH_TIME_WITH_SLASHES = `yyyy-MM-dd'T'HH:mm:ss`;

export const FULL_DATE_FORMAT = "EEE MMM dd yyyy HH:mm:ss 'GMT'xxxx (zzz)";

export const DATE_FORMAT_WITH_DASH = 'yyyy-MM-dd';

export const PAGINATION_DEFAULT_OPTION = { value: '10', label: '10' };

const getCurrencyFormatOptions = (currencyFormat?: Currency): Intl.NumberFormatOptions => ({
  style: 'currency',
  currency: currencyFormat || Currency.GBP,
  minimumFractionDigits: 2,
});

export function getFormatPrice(price: number, currencyFormat: Currency = Currency.GBP): string {
  return price.toLocaleString('en-US', getCurrencyFormatOptions(currencyFormat));
}

export function getFormatPriceUsingGBP(price: number, currencyFormat: Currency = Currency.GBP): string {
  const formattedPrice = price.toLocaleString('uk-UA', getCurrencyFormatOptions(currencyFormat));
  const lastCommaIndex = formattedPrice.lastIndexOf(',');
  if (lastCommaIndex === -1) {
    return `${formattedPrice} ${currencyFormat}`;
  }
  const priceBeforeComma = formattedPrice.slice(0, lastCommaIndex);
  const priceAfterComma = formattedPrice.slice(lastCommaIndex + 1).replace(/\D/g, '');

  return `${priceBeforeComma},${priceAfterComma} ${currencyFormat}`;
}

export const REQUIRED_FIELD_MESSAGE = 'ThisFieldIsRequired';

export const DATE_LOCALES: { [key: string]: Locale } = {
  'en-GB': enGB,
  'uk-UA': uk,
  en: enUS,
};

export const IMAGE_TYPES = [
  'image/xbm',
  'image/tif',
  'image/jfif',
  'image/ico',
  'image/svg',
  'image/webp',
  'image/svgz',
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/bmp',
  'image/pjp',
  'image/apng',
  'image/pjpeg',
  'image/avif',
];

export const CLEAR_BUTTON_STYLES =
  'text-sm rounded py-[7px] px-3 border border-solid dark:text-white hover:bg-lightGray6 bg-transparent border-blue3 text-blue3 dark:bg-darkBlue dark:hover:bg-darkBlue2';

export const DEFAULT_PAGE_SIZE = { width: 0, height: 0 };
