import { useTranslation } from 'react-i18next';

import { useLazyGetSageAuthLinkQuery, usePostSageDisconnectAccountMutation } from 'apis/sage';
import { SAGE_TABS } from 'constants/sage';
import { useOpen } from 'hooks/useOpen';
import { GetConnectionDetailsResponse } from 'interfaces/integrations/sage.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Box, Button, ErrorModal, WarningModal } from 'shared-components';
import Tabs from 'shared-components/Tabs';
import { successNotify } from 'utils/notifications';
import { getSageLink } from 'utils/url';

interface Props {
  isConnect: boolean;
  triggerRefetch: () => void;
  connectionData?: GetConnectionDetailsResponse;
  isLoading: boolean;
}

const SageConnectionHandler = ({ isConnect, triggerRefetch, connectionData, isLoading }: Props) => {
  const { t } = useTranslation();
  const [isOpenWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();
  const [disconnectSage, { error: disconnectAccountError }] = usePostSageDisconnectAccountMutation();
  const [getUrl, { error: bankAuthUrlError }] = useLazyGetSageAuthLinkQuery();

  const errorDisconnectAccountData = (disconnectAccountError as CommonError)?.data || {};
  const errorBankAuthUrlData = (bankAuthUrlError as CommonError)?.data || {};

  const errorData = errorDisconnectAccountData || errorBankAuthUrlData;

  const handleConnectSage = async () => {
    try {
      const { data } = await getUrl();
      const link = getSageLink(data?.result || '');
      window.location.href = link;
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  const handleDisconnectSage = async () => {
    try {
      await disconnectSage().unwrap();
      triggerRefetch();
      handleCloseWarningModal();
      successNotify(t('DisconnectedSuccessfully'));
    } catch (error) {
      handleCloseWarningModal();
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={t('DoYouWantToDeleteYourSageConnection')}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={handleDisconnectSage}
      />
      {!isConnect ? (
        <Box>
          <Button isLoading={isLoading} data-cy={'connect-sage-button'} onClick={handleConnectSage}>
            {t('ConnectSage')}
          </Button>
        </Box>
      ) : (
        <>
          <Box>
            <div className="grid grid-cols-2">
              <div className="col-span-1 flex flex-col justify-center">
                <p className="mb-2">{t('SageAccountConnected')}.</p>
                <p>{t('ConnectionExpiresInDays', { 0: connectionData?.result.expiresInDays })}.</p>
              </div>
              <div className="col-span-1 flex items-center justify-end">
                <Button
                  isLoading={isLoading}
                  className="float-right"
                  data-cy="disconnect-sage-button"
                  onClick={handleOpenWarningModal}
                >
                  {t('DisconnectSage')}
                </Button>
              </div>
            </div>
          </Box>
          <div className="pt-5">
            <Tabs tabs={SAGE_TABS} />
          </div>
        </>
      )}
    </>
  );
};

export default SageConnectionHandler;
