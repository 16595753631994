import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { cn } from 'utils/global';

import Button from './button/Button';

export interface Props {
  closeButtonText?: string;
  saveButtonText?: string;
  onSubmit?: VoidFunction;
  onClose: VoidFunction;
  isError?: boolean;
  isShownSubmit?: boolean;
  className?: string;
  dataCy?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

const ModalControlButtons: FC<Props> = ({
  closeButtonText = 'Cancel',
  saveButtonText = 'Save',
  onSubmit,
  onClose,
  isError,
  className,
  isShownSubmit,
  isLoading,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <div
      data-cy={'modal-control-button'}
      className={cn(cn('flex pt-4 rounded mt-5 place-content-center', { 'justify-between': !isError }), className)}
    >
      <Button
        className={cn(
          'pb-3 border rounded self-center background-transparent text-sm outline-none transition-all duration-150 ',
          { 'bg-blue4 border-0 hover:bg-linearGradient text-white': isError },
          {
            'text-blue3 dark:text-gray dark:hover:bg-lightGray3 hover:bg-lightGray3 dark:hover:bg-opacity-5 bg-white dark:bg-transparent':
              !isError,
          }
        )}
        type="button"
        dataCy="close-button"
        onClick={onClose}
      >
        {isError ? t('Ok') : t(closeButtonText)}
      </Button>
      {(onSubmit || isShownSubmit) && (
        <Button
          className={
            'hover:bg-linearGradient self-center bg-blue max-w-full text-white text-sm rounded shadow mr-1 mb-1 ease-linear transition-all duration-150 disabled:opacity-75 disabled:cursor-not-allowed'
          }
          type={onSubmit ? 'button' : 'submit'}
          isLoading={isLoading}
          onClick={onSubmit}
          dataCy="submit-button"
          disabled={disabled}
        >
          {t(saveButtonText)}
        </Button>
      )}
    </div>
  );
};

export default ModalControlButtons;
