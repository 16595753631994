import { useTranslation } from 'react-i18next';

import { useLazyGetAuthorizationUrlQuery } from 'apis/docusign.api';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { Box, Button, ErrorModal } from 'shared-components';

import DocusignSigningRequestsPage from './DocusignSigningRequestsPage';

interface Props {
  isConnect: boolean;
}

const DocusignConnectionHandler = ({ isConnect }: Props) => {
  const { t } = useTranslation();
  const [getUrl, { error: bankAuthUrlError }] = useLazyGetAuthorizationUrlQuery();
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const errorAuthorizationPayPalUrlData = (bankAuthUrlError as CommonError)?.data || {};

  const errorData = errorAuthorizationPayPalUrlData;

  const handleConnectDocusign = async () => {
    try {
      const { data } = await getUrl();
      window.location.href = data?.result || '';
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      {!isConnect ? (
        <Box>
          <Button data-cy={'connect-docusign-button'} onClick={handleConnectDocusign}>
            {t('ConnectDocusign')}
          </Button>
        </Box>
      ) : (
        <DocusignSigningRequestsPage />
      )}
    </>
  );
};

export default DocusignConnectionHandler;
