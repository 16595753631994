import { FC, useState } from 'react';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { CLEAR_BUTTON_STYLES } from 'constants/global';
import { OPERATIONS } from 'constants/os-audit-logs';
import { NameType } from 'interfaces/integrations/os-audit-logs.interface';
import { AdvancedFilter, Box, Button } from 'shared-components';
import { cn } from 'utils/global';

interface Props {
  onSearch: (filters: string) => void;
  handleClear: VoidFunction;
  isLoading: boolean;
  result?: NameType[];
}

const AdvancedFilterHandler: FC<Props> = ({ onSearch, handleClear, isLoading, result }) => {
  const { t } = useTranslation();
  const { control } = useForm();
  const [filterKey, setFilterKey] = useState<string>(uuidv4());

  const [localFilters, setLocalFilters] = useState<string>();

  const operationOptions = OPERATIONS.map((op) => ({
    label: op.name,
    value: 'null',
    type: op.type,
  }));

  const transformData = (data: NameType[]) => {
    return data.map((item: { name: string; type: string }) => ({
      value: t(item.name),
      label: t(item.name),
      type: item.type,
    }));
  };

  const filterOptions = transformData(result || []);

  const handleFilterChange = (queryString?: string) => {
    setLocalFilters(queryString);
  };

  const handleSearch = () => {
    if (localFilters) {
      onSearch(localFilters);
    }
  };

  const handleClearFilters = () => {
    handleClear();
    setLocalFilters('');
    setFilterKey(uuidv4());
  };

  return (
    <>
      <Box dataCy="advanced-filter-block" className="mb-8">
        <AdvancedFilter
          key={filterKey}
          isLoading={isLoading}
          control={control}
          options={operationOptions.concat(filterOptions)}
          className="mb-4 border rounded-md border-solid dark:text-white hover:border-blue5 dark:hover:border-blue5 dark:border-blue3 border-lightGray2 text-blue3 dark:bg-darkBlue4"
          name={''}
          onFilterChange={handleFilterChange}
          placeholder={t('Press and start typing...')}
        />
        <div className="dark:bg-gray8 dark:text-white bg-orange text-darkBlue12 text-sm italic mt-6 mb-4 px-5 py-3 rounded border border-solid border-orange1 dark:border-gray8">
          {t('DefaultSearchRules')}
        </div>
        <div className="flex justify-between">
          <Button
            isLoading={isLoading}
            dataCy="clear-search-filter"
            onClick={handleClearFilters}
            type="reset"
            className={cn('px-3', CLEAR_BUTTON_STYLES)}
          >
            {t('Clear')}
          </Button>
          <Button
            isLoading={isLoading}
            dataCy="submit-search-filter"
            onClick={handleSearch}
            type="submit"
            className="px-3"
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </Button>
        </div>
      </Box>
    </>
  );
};

export default AdvancedFilterHandler;
