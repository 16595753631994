import { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { Tooltip } from 'react-tooltip';

import { useLazyGetGooglePlacePredictionsQuery } from 'apis/samples.api';
import { OptionType } from 'interfaces/shared.interface';
import AsyncSelectComponent from 'shared-components/select/AsyncSelectComponent';

interface Props {
  dataCy?: string;
}

const AutoCompleteUsingGoogle = ({ dataCy }: Props) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [, setSingleNamesInputValue] = useState('');
  const [searchMenuOpen, satSearchMenuOpen] = useState(false);

  const [getGooglePlacePredictions] = useLazyGetGooglePlacePredictionsQuery();

  const loadOptions = async (inputValue: string): Promise<OptionsOrGroups<OptionType, GroupBase<OptionType>>> => {
    if (inputValue.length < 3) {
      satSearchMenuOpen(false);
      return [];
    }
    const response = await getGooglePlacePredictions({ SearchWord: inputValue });
    const result = response?.data?.result?.autoCompleteItems || [];
    satSearchMenuOpen(true);
    return result.map((item: string) => ({
      value: item,
      label: item,
    }));
  };

  const handleSingleOptionClick = (item: OptionType | null) => {
    setSingleNamesInputValue(item?.label || '');
    satSearchMenuOpen(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.placeholder = t('Search');
    }
  }, [t]);

  return (
    <div data-cy={dataCy}>
      <div data-cy={'single-select-autocomplete-using-google'} data-tooltip-id="tooltip-single-using-google">
        <h2 className="pb-2 text-black dark:text-white">{t('GoogleAutoComplete')}</h2>
        <div className="pb-5">
          <Tooltip
            id="tooltip-single-using-google"
            place="left"
            positionStrategy="fixed"
            className="max-w-[170px] font-light z-30"
          >
            {t('GooglePlacesAutoCompleteDescription')}
          </Tooltip>
          <AsyncSelectComponent
            loadOptions={loadOptions}
            onClickHandler={handleSingleOptionClick}
            menuIsOpen={searchMenuOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default AutoCompleteUsingGoogle;
