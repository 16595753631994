import { useEffect, useCallback } from 'react';

import { useLazyGetOsAuditLogsQuery } from 'apis/os-audit-logs.api';
import { OPERATION_LOGS_TABLE_COLUMNS } from 'constants/os-audit-logs';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { CommonError } from 'interfaces/shared.interface';
import { LoadingOverlay, Pagination, Table, ErrorModal } from 'shared-components';

import OperationLogsFilter from './OperationLogsFilter';
import OperationLogsRow from './OperationLogsRow';

const OperationLogs = () => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { filterParams, applyFilters } = useFilters();
  const { paginationParams, handleSetMaxResultCount, maxResultCount, handleSetCurrentPage, currentPage } =
    usePagination();
  const { handleSetTabName, sortingParams, sortingColumnId, sortingType } = useTableSorting();

  const [getAuditLogs, { data: auditLogsData, isLoading, isFetching, error }] = useLazyGetOsAuditLogsQuery();

  const { error: errorData } = (error as CommonError)?.data || {};

  const { result } = auditLogsData || {};

  const handleSearch = useCallback(
    (newFilterParams: string) => {
      const params = new URLSearchParams(newFilterParams);
      const filterObject = Object.fromEntries(params.entries());
      applyFilters(filterObject);
    },
    [applyFilters]
  );

  useEffect(() => {
    getAuditLogs(`${filterParams}${sortingParams}${paginationParams}`);
  }, [filterParams, paginationParams, sortingParams]);

  useEffect(() => {
    if (errorData) {
      handleOpenErrorModal();
    }
  }, [errorData, handleOpenErrorModal]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy="operation-logs-tab" className="relative">
        <LoadingOverlay isLoading={isFetching} />

        <OperationLogsFilter
          isTesting
          onSearch={(filters) => handleSearch(filters)}
          handleClear={() => applyFilters('')}
        />

        <Table
          dataCy="operation-logs-table"
          columns={OPERATION_LOGS_TABLE_COLUMNS}
          handleSetTabName={handleSetTabName}
          sortingColumnId={sortingColumnId}
          isTableEmpty={!result?.items.length}
          sortingType={sortingType}
          isLoading={isLoading}
        >
          {result?.items.map((item) => <OperationLogsRow key={item.id} logData={item} />)}
        </Table>
        <Pagination
          totalCount={result?.totalCount}
          handleSetMaxResultCount={handleSetMaxResultCount}
          maxResultCount={maxResultCount}
          handleSetCurrentPage={handleSetCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </>
  );
};

export default OperationLogs;
