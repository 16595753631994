import SignersBlock from 'page-components/integrations/docusign/SignersBlock';
import AppContentLayout from 'shared-components/AppContentLayout';

const CypressTestsPage = () => {
  return (
    <>
      <AppContentLayout title="CypressTestsPage">
        <div className="relative" data-cy="sypress-tests-page">
          <SignersBlock />
        </div>
      </AppContentLayout>
    </>
  );
};

export default CypressTestsPage;
