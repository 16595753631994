import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { usePostFilterAutoCompleteItemsMutation } from 'apis/samples.api';
import { OptionType } from 'interfaces/shared.interface';
import AsyncSelectComponent from 'shared-components/select/AsyncSelectComponent';

interface Props {
  dataCy?: string;
}

const AutoComplete = ({ dataCy }: Props) => {
  const { t } = useTranslation();
  const [, setSingleNamesInputValue] = useState('');
  const [, setMultipleNamesInputValue] = useState('');

  const [postFilterAutoCompleteItems] = usePostFilterAutoCompleteItemsMutation();

  const loadOptions = async (inputValue: string) => {
    if (!inputValue) {
      return [];
    }

    const response = await postFilterAutoCompleteItems({ searchWord: inputValue }).unwrap();
    if (!response) {
      return [];
    }

    const options = response.result.autoCompleteItems.map((item) => ({ value: item, label: item }));
    return [{ options: options }];
  };

  const handleSingleOptionClick = (item: OptionType | null) => {
    setSingleNamesInputValue(item?.label || '');
  };

  const handleMultiOptionClick = (item: OptionType | null) => {
    setMultipleNamesInputValue(item?.label || '');
  };

  return (
    <div data-cy={dataCy}>
      <div data-cy={'single-select-autocomplete'} data-tooltip-id="tooltip-single">
        <h2 className="pb-2 text-black dark:text-white">{t('Names')}</h2>
        <div className="pb-5">
          <Tooltip id="tooltip-single" place="left" positionStrategy="fixed" className="max-w-[170px] font-light z-30">
            {t('NamesAutoCompleteDescription')}
          </Tooltip>
          <AsyncSelectComponent loadOptions={loadOptions} onClickHandler={handleSingleOptionClick} />
        </div>
      </div>
      <div className="w-80" data-cy={'multi-select-autocomplete'} data-tooltip-id="tooltip-multiple">
        <h2 className="pb-2 relative mt-5 text-black dark:text-white">{t('NamesMultipleDropdown')}</h2>
        <div className="pb-5">
          <Tooltip
            id="tooltip-multiple"
            place="left"
            positionStrategy="fixed"
            className="max-w-[170px] font-light z-30"
          >
            {t('NamesMultipleDropdownAutoCompleteDescription')}
          </Tooltip>
          <AsyncSelectComponent loadOptions={loadOptions} isMulti onClickHandler={handleMultiOptionClick} />
        </div>
      </div>
    </div>
  );
};

export default AutoComplete;
